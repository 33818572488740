const INITIAL_STATE = { 
    registro: {}, 
    modoTela: 'lista', 
    aguardando: false,
    lista: [],
    listaItem: [],
    listaLoja: [],
    listaCategoria: [],
    listaProdutoModelo: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'PEDIDO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};
    
    case 'PEDIDO_SET_AGUARDANDO':
			return { ...state,
				aguardando: action.payload
			};

        case 'PEDIDO_LISTADO':
            return { ...state, lista: action.payload.data };

        case 'PEDIDO_PEDIDO_ITEM_LISTADO':
            return { ...state, listaItem: action.payload.data };

        case 'PEDIDO_CATEGORIA_SELECT_LISTADO':
			return {
				...state,
				listaCategoria: action.payload.data
			};

        case 'PEDIDO_LOJA_SELECT_LISTADO':
            return {
                ...state,
                listaLoja: action.payload.data
            };        
                
        case 'PEDIDO_PRODUTO_MODELO_SELECT_LISTADO':
            return {
                ...state,
                listaProdutoModelo: action.payload.data
            };

        default:
            return state;
    }
}
