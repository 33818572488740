import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import AuthOrApp from './authOrApp'
import Dashboard from '../dashboard/dashboard';
import Usuario from '../usuario/usuario';
import Regiao from '../regiao/regiao';
import LocalEstoque from '../localEstoque/localEstoque';
import Loja from '../loja/loja';
import ProdutoCategoria from '../produtoCategoria/produtoCategoria';
import Produto from '../produto/produto';
import ProdutoModelo from '../produtoCategoria/produtoCategoriaModelo/produtoModelo/produtoModelo';
import Pedido from '../pedido/pedido';
import PedidoLoja from '../pedido/pedidoLoja';
import PedidoItem from '../pedido/pedidoItem/pedidoItem';
import ProdutoCategoriaModelo from '../produtoCategoria/produtoCategoriaModelo/produtoCategoriaModelo';

export default props => (
	<div id='id-content-wrapper' className='content-wrapper' >
		<Switch>
			<Route exact path='/' component={PedidoLoja} />
			<Route path='/usuario' component={Usuario} />
			<Route path='/regiao' component={Regiao} />
			<Route path='/localEstoque' component={LocalEstoque} />
			<Route path='/loja' component={Loja} />
			<Route path='/produtoCategoria' component={ProdutoCategoria} />
			<Route path='/produtoCategoriaModelo' component={ProdutoCategoriaModelo} />
			<Route path='/produto' component={Produto} />
			<Route path='/produtoModelo' component={ProdutoModelo} />
			<Redirect from='*' to='/' />
		</Switch>
	</div>
);
