import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import FormatUtils from '../common/formatUtils/FormatUtils';
import DateFormat from '../common/dateFormat/DateFormat';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'PEDIDO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('pedidoForm', registro)
    ];
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/pedido`);
    return {
        type: 'PEDIDO_LISTADO',
        payload: request
    };
}

export function setAguardando(aguardando) {
    return {
        type: 'PEDIDO_SET_AGUARDANDO',
        payload: aguardando
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
		if (!registro.id) {

			registro = {
				...registro,
				datahora_pedido: DateFormat.getDataHoraAtual()
			};
	        axios.post(`${consts.API_URL}/pedido`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(getListaItem(resp.data.id));
				dispatch(initForm({
					...registro,
					id: resp.data.id
				}));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/pedido`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/pedido?id=${registro.id}&id_usuario_alteracao=${getState().auth.usuario.id}`)
		.then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista());
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
    };
}

export function getListaLoja() {
    const request = axios.get(`${consts.API_URL}/loja/listarSelect`);
    return {
        type: 'PEDIDO_LOJA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaCategoria() {
    const request = axios.get(`${consts.API_URL}/produtoCategoria/listarSelect`);
    return {
        type: 'PEDIDO_CATEGORIA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaProdutoModelo() {
    const request = axios.get(`${consts.API_URL}/produtoModelo/listarSelect`);
    return {
        type: 'PEDIDO_PRODUTO_MODELO_SELECT_LISTADO',
        payload: request
    };
}

//ITENS

export function getListaItem(idPedido) {
    const request = axios.get(`${consts.API_URL}/pedidoItem?id_pedido=${idPedido}`);
    return {
        type: 'PEDIDO_PEDIDO_ITEM_LISTADO',
        payload: request
    };
}

export function salvarItem(registro, evento) {
    return (dispatch, getState) => {
		dispatch(setAguardando(true));
		if (!registro.id) {
	        axios.post(`${consts.API_URL}/pedidoItem`, {
				...registro,
				id_usuario_inclusao: getState().auth.usuario.id,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				dispatch(getListaItem(registro.id_pedido));
				evento();
				dispatch(setAguardando(false));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		} else {
			axios.put(`${consts.API_URL}/pedidoItem`, {
				...registro,
				id_usuario_alteracao: getState().auth.usuario.id
			}).then(resp => {
				dispatch(getListaItem(registro.id_pedido));
				evento();
				dispatch(setAguardando(false));
			}).catch(e => {
				setErroAPI(e);
				dispatch(setAguardando(false));
			});
		}
    };
}

export function imprimir(registro) {
	return async (dispatch, getState) => {
		let listaItens = [];
		await axios.get(`${consts.API_URL}/pedidoItem?id_pedido=${registro.id}`).then(result => {
			listaItens = result.data.filter(item => item.quantidade > 0);
		}).catch(e => {
			setErroAPI(e);
		});

		await axios.post(`${consts.API_URL}/pedido/imprimir`, {
			...registro,
			datahora_pedido: DateFormat.formatarDataHoraSqlParaTela(registro.datahora_pedido),
			status: registro.datahora_cancelamento ? 'Cancelado' : (registro.datahora_confirmacao ? 'Confirmado' : 'Aberto'),
			datahora_cancelamento: DateFormat.formatarDataHoraSqlParaTela(registro.datahora_cancelamento),
			datahora_confirmacao: DateFormat.formatarDataHoraSqlParaTela(registro.datahora_confirmacao),
			lista: listaItens,
			id_usuario_impressao: getState().auth.usuario.id
		}).then(resp => {
			window.open(`${consts.URL}/${resp.data}`, '_blank');
			dispatch(getLista());
		}).catch(e => {
			setErroAPI(e);
		});
	}
}