const INITIAL_STATE = { 
    registro: {}, 
    modoTela: 'lista', 
    lista: [], 
    listaCategoria: [],
    listaCor: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'PRODUTO_MODELO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};

        case 'PRODUTO_MODELO_LISTADO':
            return { ...state, lista: action.payload.data };
        
        case 'PRODUTO_MODELO_COR_SELECT_LISTADO':
            return { ...state, listaCor: action.payload.data };

        default:
            return state;
    }
}
